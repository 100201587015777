window._ = require('lodash');

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
var $ = window.$ = window.jQuery = require( 'jquery' );
//window.Popper = require('popper.js').default;
import Popper from 'popper.js/dist/umd/popper.js';
window.Popper = Popper;
require('bootstrap');
require('datatables.net-dt');
