require('./bootstrap');

import Alpine from 'alpinejs';
import 'bootstrap';

window.Alpine = Alpine;

$(() => {
    Alpine.start();
    $('.ttp').tooltip()
});

